define("discourse/plugins/discourse-yearly-review/discourse/components/yearly-review-admin-notice", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/replace-emoji", "discourse-common/helpers/html-safe", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "discourse-i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _replaceEmoji, _htmlSafe, _i18n, _getUrl, _discourseI18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.janNextYear = janNextYear;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function janNextYear() {
    return new Date(new Date().getFullYear() + 1, 0, 1);
  }
  let YearlyReviewAdminNotice = _exports.default = (_class = (_class2 = class YearlyReviewAdminNotice extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get toBeCreatedDate() {
      return moment(janNextYear()).format(_discourseI18n.default.t("dates.full_with_year_no_time"));
    }
    get settingsUrl() {
      return (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=plugin%3Adiscourse-yearly-review");
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="yearly-review-admin-notice alert alert-info">
        {{replaceEmoji
          (htmlSafe
            (i18n
              "yearly_review.admin_notice"
              to_be_created_date=this.toBeCreatedDate
              settings_url=this.settingsUrl
            )
          )
        }}
      </div>
    
  */
  {
    "id": "xvwlriwT",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"yearly-review-admin-notice alert alert-info\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[28,[32,1],[[28,[32,2],[\"yearly_review.admin_notice\"],[[\"to_be_created_date\",\"settings_url\"],[[30,0,[\"toBeCreatedDate\"]],[30,0,[\"settingsUrl\"]]]]]],null]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-yearly-review/discourse/components/yearly-review-admin-notice.js",
    "scope": () => [_replaceEmoji.default, _htmlSafe.default, _i18n.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});